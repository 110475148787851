import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import Settings from 'lib/settings';
import { AppState } from 'store/store';

import ExamResourceApi from 'api/ExamResourceApi';
import QuestionApi from 'api/QuestionApi';
import ApiCaller from 'lib/ApiCaller';

import Comments from '../comments/comments';

import TinyMCETextEditor from 'components/TinyMCETextEditor';
import { useSyllabus } from 'hooks';
import DetailLayout from 'layouts/DetailLayout';
import ExamResource from 'lib/common/models/examResource';
import ExamResourceCollection from 'lib/common/models/examResourceCollection';
import Question, { QuestionStatus } from 'lib/common/models/question';
import { UserTypesLabels } from 'lib/common/models/user';
import { SanitizeQuestionString } from 'utils/sanitizer';
import LoadingSpinner from 'views/components/loadingSpinner/loadingSpinner';

const QuestionDetail: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const history = useHistory();
  const [currentQuestion, setCurrentQuestion] = useState<Question>();
  const currentQuestionRef = useRef<Question>();
  currentQuestionRef.current = currentQuestion;

  const { syllabus } = useSyllabus();
  const [modules, setModules] = useState<any[]>([]);
  const [topic, setTopics] = useState<any[]>([]);
  const [resources, setResources] = useState<ExamResource[]>([]);
  const [downloadingPDF, setDownloadingPDF] = useState(false);

  useEffect(() => {
    const selectedQuestion = Settings.getCurrentAdminQuestion();

    const questionApi = new QuestionApi(new ApiCaller(loggedUser.token));

    if (selectedQuestion.syllabus) {
      questionApi
        .getModulesFromSyllabus(selectedQuestion.syllabus._id)
        .then((m: any) => {
          setModules(m.modules);
        });
    }

    if (selectedQuestion.module) {
      questionApi
        .getTopicsFromModule(selectedQuestion.module._id)
        .then((t: any) => {
          setTopics(t.topics);
        });
    }

    const examResourceApi = new ExamResourceApi(
      new ApiCaller(loggedUser.token),
    );
    examResourceApi.getAll().then((data: ExamResourceCollection) => {
      setResources(data.resources);
    });

    if (selectedQuestion.id) {
      questionApi
        .getQuestionById(selectedQuestion.id)
        .then((question: Question) => {
          setCurrentQuestion(question);
        });
    }
  }, [loggedUser.token]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const questionInfo = currentQuestionRef.current?.toObject();
    if (questionInfo) {
      questionInfo[name] = value;
      setCurrentQuestion(new Question(questionInfo));
    }
  };

  const onStatusChange = (e: any) => {
    const questionInfo = currentQuestion?.toObject();
    if (questionInfo) {
      questionInfo['status'] = e.value;
      setCurrentQuestion(new Question(questionInfo));
    }
  };

  const onSyllabusChange = (e: any) => {
    const callNumberInfo = currentQuestion?.toObject();
    if (callNumberInfo) {
      callNumberInfo['syllabus'] = e;
      setCurrentQuestion(new Question(callNumberInfo));
      const questionApi = new QuestionApi(new ApiCaller(loggedUser.token));
      questionApi.getModulesFromSyllabus(e._id).then((m: any) => {
        setModules(m.modules);
      });
    }
  };

  const onModuleChange = (e: any) => {
    const callNumberInfo = currentQuestion?.toObject();
    if (callNumberInfo) {
      callNumberInfo['module'] = e;
      setCurrentQuestion(new Question(callNumberInfo));
      const questionApi = new QuestionApi(new ApiCaller(loggedUser.token));
      questionApi.getTopicsFromModule(e._id).then((t: any) => {
        setTopics(t.topics);
      });
    }
  };

  const onTopicChange = (e: any) => {
    const callNumberInfo = currentQuestion?.toObject();
    if (callNumberInfo) {
      callNumberInfo['topic'] = e;
      setCurrentQuestion(new Question(callNumberInfo));
    }
  };

  const onResourceChange = (e: any) => {
    const questionInfo = currentQuestion?.toObject();
    if (questionInfo) {
      questionInfo['resources'] = e;
      setCurrentQuestion(new Question(questionInfo));
    }
  };

  const updateQuestion = () => {
    if (
      (currentQuestion && currentQuestion.name === '') ||
      (currentQuestion && currentQuestion.status === '')
    ) {
      alert('Field Required');
    }
    if (currentQuestion) {
      const questionApi = new QuestionApi(new ApiCaller());
      questionApi
        .updateQuestion(currentQuestion, loggedUser.token)
        .then(() => {
          toast.success('Updated successfully');
        })
        .catch((err) => {
          toast.error('Error updating question, please try again');
        });
    }
  };

  const onGetPDF = () => {
    if (currentQuestion) {
      setDownloadingPDF(true);
      const questionApi = new QuestionApi(new ApiCaller(loggedUser.token));
      questionApi
        .getPDF(currentQuestion)
        .then(() => {
          toast.success('Question downloaded successfully');
        })
        .catch((err) => {
          toast.error('Error downloading question, please try again');
        })
        .finally(() => setDownloadingPDF(false));
    }
  };

  const close = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <div className="content">
        <DetailLayout title={'Go back'}>
          {currentQuestion && (
            <LoadingSpinner
              spinning={downloadingPDF}
              tip="Downloading question as PDF">
              <Card>
                <CardHeader>
                  <h4 className="card-title">Question details</h4>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontal">
                    <Row>
                      <Label sm="2">Question ID</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={currentQuestion.name}
                            onChange={handleChange}
                            type="text"
                            name="name"
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Question Description</Label>
                      <Col sm="10">
                        <FormGroup>
                          <TinyMCETextEditor
                            value={SanitizeQuestionString(
                              currentQuestion.description,
                            )}
                            onChange={(e: any) =>
                              handleChange({
                                target: { name: 'description', value: e },
                              })
                            }
                            isDisabled={
                              !loggedUser.hasWritePermission(currentQuestion)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Associated Answer</Label>
                      <Col sm="10">
                        <FormGroup>
                          <TinyMCETextEditor
                            value={SanitizeQuestionString(
                              currentQuestion.associatedAnswer,
                            )}
                            onChange={(e: any) =>
                              handleChange({
                                target: { name: 'associatedAnswer', value: e },
                              })
                            }
                            isDisabled={
                              !loggedUser.hasWritePermission(currentQuestion)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Syllabus</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="syllabus"
                            value={currentQuestion?.syllabus}
                            onChange={onSyllabusChange}
                            options={syllabus}
                            getOptionLabel={(item) =>
                              item.code + ' ' + item.title
                            }
                            getOptionValue={(item) => item.code}
                            placeholder="Choose a syllabus"
                            isDisabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Module</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="module"
                            value={currentQuestion?.module}
                            onChange={onModuleChange}
                            options={modules}
                            getOptionLabel={(item) =>
                              item.code + ' ' + item.title
                            }
                            getOptionValue={(item) => item.code}
                            placeholder="Choose a module"
                            isDisabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Topic</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="topic"
                            value={currentQuestion?.topic}
                            onChange={onTopicChange}
                            options={topic}
                            getOptionLabel={(item) =>
                              item.code + ' ' + item.title
                            }
                            getOptionValue={(item) => item.code}
                            placeholder="Choose a topic"
                            isDisabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Total Mark</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={currentQuestion?.topic?.marks}
                            onChange={handleChange}
                            type="number"
                            name="marks"
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {loggedUser.isType(UserTypesLabels.questionWriter) ? (
                      ' '
                    ) : (
                      <Row>
                        <Label sm="2">Status</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="status"
                              value={{
                                label: QuestionStatus[currentQuestion?.status],
                                value: currentQuestion?.status,
                              }}
                              onChange={onStatusChange}
                              options={Object.keys(QuestionStatus).map(
                                function (status) {
                                  return {
                                    value: status,
                                    label: QuestionStatus[status],
                                  };
                                },
                              )}
                              placeholder="Status"
                              isDisabled={
                                !loggedUser.isType(UserTypesLabels.admin)
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Label sm="2">Resources</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Select
                            isMulti
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="resources"
                            value={currentQuestion?.resources}
                            onChange={onResourceChange}
                            options={resources}
                            getOptionLabel={(item) => item.name || ''}
                            getOptionValue={(item) => item.id || ''}
                            placeholder="Choose question resources"
                            isDisabled={
                              !loggedUser.hasWritePermission(currentQuestion)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2" />
                      <Col sm="10">
                        {loggedUser.hasWritePermission(currentQuestion) && (
                          <Button
                            className="btn-round btn btn-info"
                            onClick={updateQuestion}>
                            Update
                          </Button>
                        )}
                        <Button
                          className="btn-round btn btn-danger"
                          onClick={close}>
                          Close
                        </Button>
                        <Button
                          className="btn-round btn btn-danger"
                          onClick={onGetPDF}>
                          PDF
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </LoadingSpinner>
          )}

          {currentQuestion && <Comments question={currentQuestion} />}
        </DetailLayout>
      </div>
      <ToastContainer />
    </>
  );
};

export default QuestionDetail;
